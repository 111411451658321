@tailwind base;
@tailwind components;
@tailwind utilities;

/* impo */

@font-face {
	font-family: "Switzer";
	src: url("../src/Fonts/WEB/fonts/Switzer-Variable.eot") format("woff2"),
		url("../src/Fonts/WEB/fonts/Switzer-Variable.woff") format("woff"),
		url("../src/Fonts/WEB/fonts/Switzer-Variable.ttf") format("truetype");
	font-display: swap;
	font-style: normal;
}

/* smae but thin Switzer-Thin.eot  */
@font-face {
	font-family: "Switzer-thin";
	src: url("../src/Fonts/WEB/fonts/Switzer-Thin.eot") format("woff2"),
		url("../src/Fonts/WEB/fonts/Switzer-Thin.woff") format("woff"),
		url("../src/Fonts/WEB/fonts/Switzer-Thin.ttf") format("truetype");
	font-display: swap;
	font-style: normal;
}
/* switzer bold */
@font-face {
	font-family: "Switzer-bold";
	src: url("../src/Fonts/WEB/fonts/Switzer-Bold.eot") format("woff2"),
		url("../src/Fonts/WEB/fonts/Switzer-Bold.woff") format("woff"),
		url("../src/Fonts/WEB/fonts/Switzer-Bold.ttf") format("truetype");
	font-display: swap;
	font-style: normal;
}


body {
	margin: 0;
	font-family: "Switzer" !important;
}
.custom-bullet {
	visibility: hidden;
}

.nav-item{
	font-size: 20px;
	border-radius: 19px;
	font-weight: 400;
	padding:4px 24px;
	color: white;
}
/* ...existing code... */

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: #1a1a1a;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb {
    background: #F8EC11;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #F8EC11;
}

/* For Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #F8EC11 #1a1a1a;
}